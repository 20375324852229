<template>
  <div class="dropdownMenu">
    <div class="selector" @click.stop="toggleMenu" :id="selection">
      {{ selection }}
      <DownCaretIcon class="caret" :class="isMenuVisible ? 'down' : ''" />
    </div>

    <transition name="slide">
      <div class="menu" v-if="isMenuVisible">
        <div
          class="item"
          v-for="item in items"
          :key="item"
          @click="itemSelected(item)"
        >
          {{ item }}
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import DownCaretIcon from '/src/components/icons/DownCaretIcon.vue';
import { onMounted, onUnmounted, ref } from 'vue';
export default {
  components: {
    DownCaretIcon
  },

  props: {
    items: Array,
    selection: [String, Number]
  },

  setup(props, context) {
    const isMenuVisible = ref(false);
    const selectedIndex = ref(0);

    onMounted(() => {
      document.body.addEventListener('click', clickOutside);
    });

    onUnmounted(() => {
      document.body.removeEventListener('click', clickOutside);
    });

    const toggleMenu = () => {
      isMenuVisible.value = !isMenuVisible.value;
    };

    const clickOutside = event => {
      if (props.selection != event.target.outerText) {
        isMenuVisible.value = false;
      }
    };

    const itemSelected = item => {
      isMenuVisible.value = false;
      context.emit('selected', item);
    };

    return {
      toggleMenu,
      isMenuVisible,
      selectedIndex,
      itemSelected
    };
  }
};
</script>
<style scoped lang="scss">
.dropdownMenu {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  position: relative;
  .selector {
    border: 1px solid #3883fa;
    opacity: 0.8;
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 2px;
    transition: all 100ms ease-in;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //width: 200px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .caret {
      height: 5px;
      width: 10px;
      transition: all 200ms;
    }

    .down {
      transform: rotate(180deg);
    }
  }

  .menu {
    position: absolute;
    background-color: rgba(230, 255, 244, 1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 100%;
    height: fit-content;
    max-height: 200px;
    z-index: 100;
    border-radius: 10px;
    padding: 10px;
    overflow-y: scroll;
    top: 40px;

    .item {
      padding: 10px;
      border-radius: 5px;
      transition: all 200ms ease;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 200ms ease;
}

.slide-enter-from,
.slide-leave-active {
  opacity: 0;
  // height: 0px;
}
</style>
