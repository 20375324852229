'use strict';

// Imports.
import { createStore } from 'vuex';

// Import data modules for the store.
import alert from './alert';
import ethers from './ethers';
//import mint from './mint';
import generator from './generator';
import login from './login';

// Export the data store using our modules.
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    ethers,
  //  mint,
    generator,
    login
  }
});
