'use strict';

// Specified state transitions per particular actions.
export default {
  updateSelectedImpostor(state, impostorId) {
    state.selectedImpostor = impostorId;
  },
  updateImpostorMeta(state, impostorMeta) {
    state.impostorMeta = impostorMeta;
  },

  updateSelectedUfo(state, ufoId) {
    state.selectedUfo = ufoId;
  },
  updateUfoMeta(state, ufoMeta) {
    state.ufoMeta = ufoMeta;
  },

  updateSelectedPet(state, petId) {
    state.selectedPet = petId;
  },
  updatePetMeta(state, petMeta) {
    state.petMeta = petMeta;
  },

  updateGeneratedLandImage(state, generatedLandImage) {
    state.generatedLandImage = generatedLandImage;
  },

  updateGeneratedLandTraits(state, landTraits) {
    state.generatedLandTraits = landTraits;
  },

  updateWeights(state, weights){
    state.weights = weights;
  },

  updateWeightList(state, weightList){
    state.weightList = weightList;
  },

  updateRarities(state, rarities){
    state.rarities = rarities;
  },

  updateRarityList(state, rarityList){
    state.rarityList = rarityList;
  },

  updateDecorators(state, decorators){
    state.decorators = decorators;
  },

  updateDecoratorList(state, decoratorList){
    state.decoratorList = decoratorList;
  },

  updateScores(state, scores){
    state.scores = scores;
  },

  updateGalaxyString(state, galaxyString){
    state.galaxyString = galaxyString;
  }
};
