<template>
  <div class="mint">
    <div>
      <div class="curationColumn"  >

        <div class="curationCell">
         <div class="itemType">
           ALIEN
         </div>
         <TextInput v-model="impostorId" style="width:60%;"/>
         <Button class="curationButton" style="width:20%;" @click="getImpostorMeta()">get</Button>
         <div>
           <div class="metaReturn">
             <img class="metaReturnImg" :src="impostorImage" />
             <img />
           </div>
           <div class="metaReturn">
             <div
               v-for="(impAttribute, index) in impostorMeta.attributes"
               :key="index"
             >
              {{impAttribute.trait_type}}: {{impAttribute.value}}
             </div>
           </div>
         </div>
        </div>

        <div class="curationCell">
         <div class="itemType">
           UFO
         </div>
         <TextInput v-model="ufoId" style="width:60%;"/>
         <Button class="curationButton" style="width:20%;" @click="getUfoMeta()">get</Button>
         <div >
           <div class="metaReturn" >
             <img class="metaReturnImg" :src="ufoImage" />
             <img />
           </div>
           <div class="metaReturn" >
             <div
               v-for="(ufoAttribute, index) in ufoMeta.attributes"
               :key="index"
             >
              {{ufoAttribute.trait_type}}: {{ufoAttribute.value}}
             </div>
           </div>
         </div>
        </div>

        <div class="curationCell">
         <div class="itemType">
           PET
         </div>
         <TextInput v-model="petId" style="width:60%;"/>
         <Button class="curationButton" style="width:20%;" @click="getPetMeta()">get</Button>
         <div >
           <div class="metaReturn" >
             <img class="metaReturnImg" :src="petImage" />
             <img />
           </div>
           <div class="metaReturn" >
             <div
               v-for="(petAttribute, index) in petMeta.attributes"
               :key="index"
             >
              {{petAttribute.trait_type}}: {{petAttribute.value}}
            </div>
           </div>
         </div>
        </div>

        <div>
          <Button style="width:100%;" class="disabled" v-show="!armGeneration" @click="generateLandTraits()" disabled>generate</Button>
          <Button style="width:100%; background:#07c005;" v-show="armGeneration" @click="generateLandTraits()">generate</Button>
        </div>
      </div>

      <div class="traitViewer">
        <div class="curationCell" style="width:100%;">
          <div class="curationColumn"  >
            <Button style="width:100%;padding:0; background:#88afef;" v-show="editing != 'weights'" @click="editWeights()">edit weights</Button>
            <Button style="width:100%;padding:0;" v-show="editing == 'weights'"  @click="editWeights()">edit weights</Button>
          </div>
          <div class="curationColumn"  >
            <Button style="width:100%;padding:0; background:#88afef;" v-show="editing != 'rarities'" @click="editRarities()">edit rarities</Button>
            <Button style="width:100%;padding:0;" v-show="editing == 'rarities'" @click="editRarities()">edit rarities</Button>
          </div>
          <div class="curationColumn"  >
            <Button style="width:100%;padding:0; background:#88afef;" v-show="editing != 'decorators'" @click="editDecorators()">edit decorators</Button>
            <Button style="width:100%;padding:0;" v-show="editing == 'decorators'" @click="editDecorators()">edit decorators</Button>
          </div>
        </div>

        <div v-show="editing == 'none' && !loadingJson">
          <div class="curationCell" v-show="scores.length > 0">
            <table style="width:100%; border-collapse:collapse">
              <tr style="background-color: #bad4fd">
                <td style="width:150px;"> &nbsp; </td>
                <td>SCORE</td>
                <td>RANK</td>
                <td>PERCENTILE</td>
              </tr>
              <tr style="background-color: #eee">
                <td style="width:150px;">IMP</td>
                <td>{{ scores[1].score }}</td>
                <td>{{ scores[1].rank }}</td>
                <td>{{ scores[1].percentile }}</td>
              </tr>
              <tr style="background-color: #bad4fd">
                <td>UFO</td>
                <td>{{ scores[2].score }}</td>
                <td>{{ scores[2].rank }}</td>
                <td>{{ scores[2].percentile }}</td>
              </tr>
              <tr style="background-color: #eee">
                <td>PET</td>
                <td>{{ scores[3].score }}</td>
                <td>{{ scores[3].rank }}</td>
                <td>{{ scores[3].percentile }}</td>
              </tr>
              <tr style="background-color: #bad4fd">
                <td>TOTAL</td>
                <td>{{ scores[0].score }}</td>
                <td>{{ scores[0].rank }}</td>
                <td>{{ scores[0].percentile }}</td>
              </tr>
            </table>
            <br>
            <div>GalaxyString: {{ galaxyString }}</div>
          </div>
          <div class="curationCell">
            Generated Land Metadata
            <pre>{{landTraits}}</pre>
          </div>
        </div>

        <div class="jsonEdit">
          <div class="curationCell" v-show="loadingJson">
            <Skeletor class="skeleton "></Skeletor>
            <Skeletor class="skeleton "></Skeletor>
            <Skeletor class="skeleton "></Skeletor>
          </div>
          <div class="curationCell" v-show="editing == 'weights' && !loadingJson">
            <div>
              <div style="display:inline-block; width:50%">
                EDITING WEIGHTS
                <DropdownMenu
                  class="dropdown"
                  :selection="selectedWtFile"
                  :items="weightFiles"
                  @selected="selectWeightfile(index, $event)"
                />
                <Button class="curationButton" @click="loadWeightFile()">Load File</Button>

              </div>
              <div style="display:inline-block; text-align:right; width:50%">

                <Button v-show="!armSaveButton" class="disabled curationButton editor-control" @click="saveWeights()" disabled>Save</Button>
                <Button v-show="armSaveButton" class="curationButton editor-control" @click="saveWeights()">Save</Button>
                <Button class="curationButton editor-control" @click="closeEditor()">Close</Button>
              </div>
            </div>
            <div class="jsoneditor">
              <Vue3JsonEditor
                v-model="json"
                :show-btns="false"
                :expandedOnStart="true"
                @json-change="onJsonChange"
              />
            </div>
          </div>

          <div class="curationCell" v-show="editing == 'rarities' && !loadingJson">
            <div>
              <div style="display:inline-block; width:50%">
                EDITING RARITIES
                <DropdownMenu
                  class="dropdown"
                  :selection="selectedRarFile"
                  :items="rarityFiles"
                  @selected="selectRarfile(index, $event)"
                />
                <Button class="curationButton" @click="loadRarityFile()">Load File</Button>

              </div>
              <div style="display:inline-block; text-align:right; width:50%">

                <Button v-show="!armSaveButton" class="disabled curationButton" @click="saveRarities()" disabled>Save</Button>
                <Button v-show="armSaveButton" class="curationButton" @click="saveRarities()">Save</Button>
                <Button class="curationButton" @click="closeEditor()">Close</Button>

              </div>
            </div>
            <div class="jsoneditor">
              <Vue3JsonEditor
                v-model="json"
                :show-btns="false"
                :expandedOnStart="true"
                @json-change="onJsonChange"
              />
            </div>
          </div>

          <div class="curationCell" v-show="editing == 'decorators' && !loadingJson">
            <div>
              <div style="display:inline-block; width:50%">
                EDITING DECORATORS
                <DropdownMenu
                  class="dropdown"
                  :selection="selectedDecoFile"
                  :items="decoratorFiles"
                  @selected="selectDecofile(index, $event)"
                />
                <Button class="curationButton" @click="loadDecoratorFile()">Load File</Button>
              </div>
              <div style="display:inline-block; text-align:right; width:50%">

                <Button v-show="!armSaveButton" class="disabled curationButton" @click="saveDecorators()" disabled>Save</Button>
                <Button v-show="armSaveButton" class="curationButton" @click="saveDecorators()">Save</Button>
                <Button class="curationButton" @click="closeEditor()">Close</Button>

              </div>
            </div>

            <div class="jsoneditor">
              <Vue3JsonEditor
                v-model="json"
                :show-btns="false"
                :expandedOnStart="true"
                @json-change="onJsonChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
'use strict';

// Imports.
import { computed, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import { Vue3JsonEditor } from 'vue3-json-editor'
import { Skeletor } from 'vue-skeletor';

// Component imports.
import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import Button from '/src/components/ui/Button.vue';
import TextInput from '/src/components/ui/TextInput.vue';
import config from '/src/config'

// Set up the default component.
export default {
  components: {
    Button,
    DropdownMenu,
    TextInput,
    Skeletor,
    Vue3JsonEditor
  },

  setup () {
    const store = useStore();

    const isLoading = ref(true);

    const impostorId = ref('');
    const impostorImage = ref('');
    const impostorMeta = computed(() => {
      return store.state.generator.impostorMeta;
    });

    const getImpostorMeta = async () => {
      await store.dispatch(
        'generator/getImpostorMeta',
        {
          tokenId: impostorId.value
        },
        { root: true }
      );
      impostorImage.value = impostorMeta.value.image;
    };

    const ufoId = ref('');
    const ufoImage = ref('');
    const ufoMeta = computed(() => {
      return store.state.generator.ufoMeta;
    });

    const getUfoMeta = async () => {
      await store.dispatch(
        'generator/getUfoMeta',
        {
          tokenId: ufoId.value
        },
        { root: true }
      );
      ufoMeta.value.id = ufoId.value;
      ufoImage.value = ufoMeta.value.image;
    };

    const petId = ref('');
    const petImage = ref('');
    const petMeta = computed(() => {
      return store.state.generator.petMeta;
    });

    const getPetMeta = async () => {
      await store.dispatch(
        'generator/getPetMeta',
        {
          tokenId: petId.value
        },
        { root: true }
      );
      petMeta.value.id = petId.value;
      petImage.value = petMeta.value.image;
    };

    const armGeneration = computed(() => {
      return (impostorMeta.value.id && ufoMeta.value.id && petMeta.value.id);
    })

    // Generate land from the specified component item IDs.
    const generateLandTraits = async () => {
      let metadata = {
        impostorId: impostorId.value,
        ufoId: ufoId.value,
        petId: petId.value
      }
      await store.dispatch(
        'generator/generateLandTraits',
        { metadata: metadata },
        { root: true }
      );
    };

    // Return generated item scores from the generator store, or defaults.
    const scores = computed(() => {
      let emptyConfig = {
        score: 0,
        rank: 0,
        percentile: 0
      };
      let scores = store.state.generator.scores.length == 0
        ? [ emptyConfig, emptyConfig, emptyConfig, emptyConfig ]
        : store.state.generator.scores;
      return scores;
    });

    const landTraits = computed(() => {
      return store.state.generator.generatedLandTraits;
    });

    const galaxyString = computed(() => {
      return store.state.generator.galaxyString;
    });

    const editing = ref('none');
    const loadingJson = ref(false);

    // Prepare the contents of the JSON editor as reactive state.
    const state = reactive({
      json: { }
    });

    const armSaveButton = ref(false);
    const armLoadWarning = ref(false);

    // Update the JSON binding when a change happens.
    const onJsonChange = function (data) {
      state.json = data;
      armSaveButton.value = true;
      armLoadWarning.value = true;
    };

    // Close the editor by disabling editing mode.
    const closeEditor = async () => {
      let proceed = true;
      if(armSaveButton.value == true){
        proceed = confirm('You have unsaved changes, are you sure you want to close the editor?')
      }
      if(proceed){
        editing.value = 'none';
      };
    };

// // // // // //

    const weightFiles = ref([]);
    const selectedWtFile = ref('');
    const selectWeightfile = async (index, selected) => {
      selectedWtFile.value = selected
    }

    const loadWeightFile = async (  ) => {
      let proceedLoading = true;
      if(armLoadWarning.value == true){
        proceedLoading = confirm('Loading this file will overwrite your current changes, is that ok?')
      }
      if(proceedLoading){
        await store.dispatch(
          'generator/getWeights',
          { filename: selectedWtFile.value },
          { root: true }
        );
        state.json = store.state.generator.weights;
        armSaveButton.value = true;
        armLoadWarning.value = false;
      }
    }

    // Get the current trait weights.
    const getWeights = async () => {
      await store.dispatch(
        'generator/getWeights',
        { filename: selectedWtFile.value },
        { root: true }
      );
      state.json = store.state.generator.weights;
    };

    // Get the current trait weights.
    const getWeightList = async () => {
      let wtlist = ['weights.json'];
      await store.dispatch(
        'generator/getWeightList',
        { },
        { root: true }
      );
      weightFiles.value = wtlist.concat(Object.values(store.state.generator.weightList));
      selectedWtFile.value = weightFiles.value[0]
    };

    // Switch the editor into weights configuration mode.
    const editWeights = async () => {
      let proceed = true;
      if(armSaveButton.value == true){
        proceed = confirm('You have unsaved changes, are you sure you want to navigate away?')
      }
      if(proceed){
        loadingJson.value = true;
        await getWeights();
        await getWeightList();
        loadingJson.value = false;
        editing.value = 'weights';
      };
    };

    // Save the updated trait weights.
    const saveWeights = async function () {
      await store.dispatch(
        'generator/saveWeights',
        { weights: state.json },
        { root: true }
      );
      state.json = store.state.generator.weights;
      await getWeightList();
      armSaveButton.value = false;
      armLoadWarning.value = false;
    };

// // // // // //
    const rarityFiles = ref([]);
    const selectedRarFile = ref('');
    const selectRarfile = async (index, selected) => {
      selectedRarFile.value = selected
    }

    const loadRarityFile = async (  ) => {
      let proceedLoading = true;
      if(armLoadWarning.value == true){
        proceedLoading = confirm('Loading this file will overwrite your current changes, is that ok?')
      }
      if(proceedLoading){
        await store.dispatch(
          'generator/getRarities',
          { filename: selectedRarFile.value },
          { root: true }
        );
        state.json = store.state.generator.rarities;
        armSaveButton.value = true;
        armLoadWarning.value = false;
      }
    }

    const getRarities = async () => {
      await store.dispatch(
        'generator/getRarities',
        { filename: selectedRarFile.value },
        { root: true }
      );
      state.json = store.state.generator.rarities;
    };

    // Get the current trait rarities.
    const getRarityList = async () => {
      let rarlist = ['rarities.json'];
      await store.dispatch(
        'generator/getRarityList',
        { },
        { root: true }
      );
      rarityFiles.value = rarlist.concat(Object.values(store.state.generator.rarityList));
      selectedRarFile.value = rarityFiles.value[0]
    };

    const editRarities = async () => {
      let proceed = true;
      if(armSaveButton.value == true){
        proceed = confirm('You have unsaved changes, are you sure you want to navigate away?')
      }
      if(proceed){
        loadingJson.value = true;
        await getRarities();
        await getRarityList();
        loadingJson.value = false;
        editing.value = 'rarities';
      };
    };

    const saveRarities = async function () {
      await store.dispatch(
        'generator/saveRarities',
        { rarities: state.json },
        { root: true }
      );
      state.json = store.state.generator.rarities;

      await getRarityList();
      armSaveButton.value = false;
      armLoadWarning.value = false;
    };


// // // // // //
    const decoratorFiles = ref([]);
    const selectedDecoFile = ref('');
    const selectDecofile = async (index, selected) => {
      selectedDecoFile.value = selected
    }

    const loadDecoratorFile = async (  ) => {
      let proceedLoading = true;
      if(armLoadWarning.value == true){
        proceedLoading = confirm('Loading this file will overwrite your current changes, is that ok?')
      }
      if(proceedLoading){
        await store.dispatch(
          'generator/getDecorators',
          { filename: selectedDecoFile.value },
          { root: true }
        );
        state.json = store.state.generator.decorators;
        armSaveButton.value = true;
        armLoadWarning.value = false;
      }
    }

    const getDecorators = async () => {
      await store.dispatch(
        'generator/getDecorators',
        { filename: selectedDecoFile.value },
        { root: true }
      );
      state.json = store.state.generator.decorators;
    };

    // Get the current trait rarities.
    const getDecoratorList = async () => {
      let decolist = ['decorators.json'];
      await store.dispatch(
        'generator/getDecoratorList',
        { filename: selectedDecoFile.value },
        { root: true }
      );
      decoratorFiles.value = decolist.concat(Object.values(store.state.generator.decoratorList));
      selectedDecoFile.value = decoratorFiles.value[0]
    };

    const editDecorators = async () => {
      let proceed = true;
      if(armSaveButton.value == true){
        proceed = confirm('You have unsaved changes, are you sure you want to navigate away?')
      }
      if(proceed){
        await getDecorators();
        await getDecoratorList();
        editing.value = 'decorators';
      };
    };

    const saveDecorators = async function () {
      await store.dispatch(
        'generator/saveDecorators',
        { decorators: state.json },
        { root: true }
      );
      state.json = store.state.generator.decorators;

      await getDecoratorList();
      armSaveButton.value = false;
      armLoadWarning.value = false;
    };

    return {
      getImpostorMeta,
      impostorId,
      impostorImage,
      impostorMeta,

      getUfoMeta,
      ufoId,
      ufoImage,
      ufoMeta,

      getPetMeta,
      petId,
      petImage,
      petMeta,

      armGeneration,
      generateLandTraits,
      landTraits,
      galaxyString,
      scores,

      editing,
      ...toRefs(state),
      onJsonChange,
      loadingJson,
      closeEditor,
      armSaveButton,
      armLoadWarning,
      editWeights,
      saveWeights,
      weightFiles,
      selectedWtFile,
      selectWeightfile,
      loadWeightFile,

      editRarities,
      saveRarities,
      rarityFiles,
      selectedRarFile,
      selectRarfile,
      loadRarityFile,

      editDecorators,
      saveDecorators,
      decoratorFiles,
      selectedDecoFile,
      selectDecofile,
      loadDecoratorFile,

      store,
      isLoading
    };
  }
};
</script>
<style scoped lang="scss">
pre {
  font-family:monospace;
  background-color: #93bcfc;
  padding: 1%;
  color:#eee;
}

td {padding:5px 10px;}

.skeleton {
  margin: 10px 0px;
  padding: 10px;
  // padding-bottom: 220px;
  // display: flex;
  // flex-direction: row;
  align-items: center;
  background:#99f;
  height:40px;
  border-radius: 4px;
}
.mint {
  padding: 10px 20px;
  padding-bottom: 220px;
  //display: flex;
  flex-direction: column;
  align-items: center;
  color: #7a7773;
  min-height:100vh;
  //box-shadow: rgb(204 204 204) 3px 0px 5px inset;

  background:#f1ece8;
  // background: repeating-linear-gradient(
  //   -45deg,
  //   #fff,
  //   #fff 10px,
  //   #fafafa 10px,
  //   #fafafa 20px
  // );
  .jsoneditor {
    background: #efefef
  }

  .mintWrapper {
    margin-bottom: 20px;
    margin-top: 20px;

    .filled {
      border: 1px solid #7a7773;
      border-radius: 1px;
      padding: 15px;
    }
    .allocation {
      text-align: center;
      padding: 40px;
      font-size: 16px;
      line-height: 1em;
      font-weight: 700;
      border: 2px solid white;
      border-radius: 1px;
    }
  }

  .description {
    padding-top: 20px;
    opacity: 0.8;
    font-style: italic;
    font-weight: 600;
    text-align: center;
    max-width: 420px;
    line-height: 1.4em;
  }

  .tabs {
    display: flex;
    justify-content: center;
  }

  .curationButton{

    //padding:4px 10px;
    border-radius:1px;
    width:210px;
    padding:0 0;
    margin:1% 0 30px 10px;
  }

  .editor-control {
    width:120px;
  }

  .disabled {
    background: #aaa;
    color: #eee;
    cursor:not-allowed;
  }
  .disabled:hover {
    transform:none;
    box-shadow: none;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    background-color: #aaa;
    color: #eee;
  }

  .curationColumn {
    width: 31%;
    //min-width:400px;
    margin:0 1%;
    display:inline-block;
  }

  .curationCell {
    //width:100%;
    background-color: #f1f1f1;

    vertical-align: top;
    border:1px solid fafafa;
    padding:1% 1% 1% 3%;
    box-shadow: 0 0 10px #cdcdcd;
    margin:0 0 10px 0;
  }

  .traitViewer {
    width:66%;
    display:inline-block;
    vertical-align: top;
    margin: 0;
  }

  .metaReturnImg {
    //border:1px solid #444;
    width:60%;
  }

  .metaReturn {
    vertical-align: top;
    width:50%;
    display:inline-block;
  }

  .itemType {
    border:1px solid #3883fa;
    color:#3883fa;
    border-radius:1px;
    padding: 0 5px;
    display: inline-block;
    margin-right:10px;
    width:15%;
    text-align:center;
  }

  .dropdownWrapper {
    .dropdown {
      //display: grid;
      //grid-template-columns: auto 1fr;
      //margin-right: 10px;
      width: 240px;

      button {
        cursor: pointer;
        padding: 5px 20px;
        border-radius: 1px;
        height: 40px;
        -webkit-transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #7a7773;
        background-color: #aaf0d1;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 3px;
        text-decoration: none;
        text-transform: uppercase;
        border: none;
      }

      button:hover {
        box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -2px) scale(1.05);
        -ms-transform: translate(0px, -2px) scale(1.05);
        transform: translate(0px, -2px) scale(1.05);
        color: #7a7773;
      }

      button:active {
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }

      .text {
        width: 100%;
        text-align: center;
      }
    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }

  .mintCountdownWhitelist {
    border:1px solid #777;
    border-radius:10px;
    padding:15px;
  }
  .mintCountdownWhitelistText {
    text-align: center;
  }

  .mintCountdown {
    border: 1px solid white;
    border-radius: 10px;
    padding: 15px;
  }
  .mintCountdownText {
    text-align: center;

  }
}

.wrongNetworkWarning {
  width:100%;
  text-align:center;
  padding:10px 0;
  background:#FFdd00;
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
