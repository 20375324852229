<template>
  <div class="footer">
    <div class="logo" href="#splash" v-smooth-scroll>
      <img
        src="../../images/curation.png"
        alt="Footer Logo"
      />
    </div>
  </div>
</template>
<script>
</script>
<style scoped lang="scss">
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  background:#dedede;

  .logo {
    width: 240px;
    max-width: 100%;
    margin-right: auto;
    margin-bottom: 0px;
    cursor: pointer;
    transition: all 500ms ease;

    &:active {
      transform: scale(0.9);
    }
  }

  .link {
    display: flex;
    padding: 15px 20px;
    align-items: center;
    border-radius: 10px;
    transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #8ef5c7;
    text-decoration: none;
    font-size: 16px;
    background-color: transparent;
    border: none;
    height: fit-content;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background-color: hsla(0, 0%, 100%, 0.1);
    }

    &:active {
      background-color: hsla(0, 0%, 100%, 0.2);
    }
  }
}

.terms {
  display: flex;
  justify-content: flex-end;
  padding: 0px 20px 40px 20px;
  background:#dedede;

  p {
    cursor: pointer;
    color: #8ef5c7;
    opacity: 0.7;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .top {
    text-decoration: none;
    margin-right: 20px;
  }
}

@media (max-width: 1200px) {
  .footer {
    flex-wrap: wrap;
    justify-content: center;
    .logo {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .terms {
    justify-content: center;
  }
}
</style>
