'use strict';

// Imports.
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';
import config from '/src/config'

const getMeta = async function (tokenId, type) {
  let metadataResponse = await axios.get(
    `${config.traitserverUrl}/getMetadata?id=${tokenId}&type=${type}`
  );
  let metadata = metadataResponse.data;
  return metadata;
}

const getImpostorMeta = async function (tokenId, dispatch) {
  let metadata = await getMeta(tokenId, 'alien');
  return metadata;
};

const getUfoMeta = async function (tokenId, dispatch) {
  let metadata = await getMeta(tokenId, 'ufo');
  return metadata;
};

const getPetMeta = async function (tokenId, dispatch) {
  let metadata = await getMeta(tokenId, 'pet');
  return metadata;
};

/**
  Generate a new land item by sending the component item IDs to the land server.
*/
const generateLandTraits = async function (
  { impostorId, ufoId, petId },
  dispatch
) {
  let landResponse = await axios.post(`${config.traitserverUrl}/generate`, {
    inputs: [ impostorId, ufoId, petId ]
  });

  // Output the final land.
  let land = {
    name: 'Test Land',
    description: '',
    attributes: landResponse.data.land
  };

  // Return the newly-generated piece of land.
  return {
    scores: [
      landResponse.data.totalScore,
      landResponse.data.scores[0],
      landResponse.data.scores[1],
      landResponse.data.scores[2]
    ],
    galaxyString: landResponse.data.galaxyString,
    landTraits: JSON.stringify(land, null, 2),
    landImage: ''
  };
};

/**
  Get the current list of weight files stored in the item generation server.
*/
const getWeightList = async function () {
  let weightList = await axios.get(`${config.traitserverUrl}/getWeightlist`);
  return weightList.data;
}

/**
  Get the current set of trait weights being used by the item generation server.
*/
const getWeights = async function ( filename ) {
  let endpoint = ( filename ) ? `/getWeights?filename=${filename}` : `/getWeights` ;
  let weights = await axios.get(`${config.traitserverUrl}${endpoint}`);
  return weights.data;
}

/**
  Save the current set of trait weights to the item generation server.
*/
const saveWeights = async function (weights, dispatch) {
  await dispatch(
    'alert/info',
    {
      message: 'Saving Weights',
      duration: 300000
    },
    { root: true }
  );
  await axios.post(`${config.traitserverUrl}/setWeights`, weights);
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Weights Saved Successfully',
      duration: 5000
    },
    { root: true }
  );
  return weights;
};


/**
  Get the current list of rarity files stored in the item generation server.
*/
const getRarityList = async function () {
  let rarityList = await axios.get(`${config.traitserverUrl}/getRarityList`);
  return rarityList.data;
}

/**
  Get the current set of rarities being used by the item generation server.
*/
const getRarities = async function ( filename ) {
  let endpoint = ( filename ) ? `/getRarities?filename=${filename}` : `/getRarities` ;
  let rarities = await axios.get(`${config.traitserverUrl}${endpoint}`);
  return rarities.data;
}

/**
  Save the current set of rarities to the item generation server.
*/
const saveRarities = async function ( rarities,  dispatch ) {
  await dispatch(
    'alert/info',
    {
      message: 'Saving Rarities',
      duration: 300000
    },
    { root: true }
  );
  await axios.post(`${config.traitserverUrl}/setRarities`, rarities);
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Rarities Saved Successfully',
      duration: 5000
    },
    { root: true }
  );
  return rarities;
};

/**
  Get the current list of decorator files stored in the item generation server.
*/
const getDecoratorList = async function () {
  let decoratorList = await axios.get(`${config.traitserverUrl}/getDecoratorList`);
  return decoratorList.data;
}

/**
  Get the current set of decorators being used by the item generation server.
*/
const getDecorators = async function ( filename ) {
  let endpoint = ( filename ) ? `/getDecorators?filename=${filename}` : `/getDecorators` ;
  let decorators = await axios.get(`${config.traitserverUrl}${endpoint}`);
  return decorators.data;
};

/**
  Save the current set of decorators to the item generation server.
*/
const saveDecorators = async function ( decorators,  dispatch  ) {
  await dispatch(
    'alert/info',
    {
      message: 'Saving Decorators',
      duration: 30000
    },
    { root: true }
  );
  await axios.post(`${config.traitserverUrl}/setDecorators`, decorators);
  await dispatch('alert/clear', '', { root: true });
  await dispatch(
    'alert/info',
    {
      message: 'Decorators Saved Successfully',
      duration: 5000
    },
    { root: true }
  );
  return decorators;
};

// Export the service functions.
export const generatorService = {
  getMeta,
  getImpostorMeta,
  getUfoMeta,
  getPetMeta,
  generateLandTraits,
  getWeights,
  getWeightList,
  saveWeights,
  getRarities,
  getRarityList,
  saveRarities,
  getDecorators,
  getDecoratorList,
  saveDecorators
};
