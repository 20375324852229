'use strict';

import config from '/src/config';
// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  selectedImpostor: '',
  impostorMeta: {},
  selectedUfo: '',
  ufoMeta: {},
  selectedPet: '',
  petMeta: {},
  generatedLandTraits: {},
  generatedLandImage: {},
  galaxyString: '',
  scores: [],
  weights: {},
  weightList: [],
  rarities: {},
  rarityList: [],
  decorators: {},
  decoratorList: []
};


// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
