'use strict';

// Imports.
import { generatorService } from '../../services';

export default {

  //
  async getImpostorMeta({ dispatch, commit },{ tokenId }) {
    try {
      let impostorMeta = await generatorService.getImpostorMeta(
        tokenId,
        dispatch
      );
      commit('updateImpostorMeta', impostorMeta)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  //
  async getUfoMeta({ dispatch, commit },{ tokenId }) {
    try {
      let ufoMeta = await generatorService.getUfoMeta(
        tokenId,
        dispatch
      );
      commit('updateUfoMeta', ufoMeta)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  //
  async getPetMeta({ dispatch, commit },{ tokenId }) {
    try {
      let petMeta = await generatorService.getPetMeta(
        tokenId,
        dispatch
      );
      commit('updatePetMeta', petMeta)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  // Generate a piece of land given the specified item IDs.
  async generateLandTraits ({ dispatch, commit }, { metadata }) {
    try {
      let result = await generatorService.generateLandTraits(
        metadata,
        dispatch
      );
      commit('updateScores', result.scores);
      commit('updateGalaxyString', result.galaxyString);
      commit('updateGeneratedLandTraits', result.landTraits);
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getWeights ({ dispatch, commit }, { filename }) {
    try {
      let weights = await generatorService.getWeights( filename );
      commit('updateWeights', weights)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getWeightList ({ dispatch, commit }, { }) {
    try {
      let weightList = await generatorService.getWeightList( );
      commit('updateWeightList', weightList)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async saveWeights ({ dispatch, commit }, { weights }) {
    try {
      await generatorService.saveWeights(
        weights,
        dispatch
      );
      commit('updateWeights', weights)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getRarities({ dispatch, commit },{ filename }) {
    try {
      let rarities = await generatorService.getRarities( filename );
      commit('updateRarities', rarities)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getRarityList ({ dispatch, commit }, { }) {
    try {
      let rarityList = await generatorService.getRarityList( );
      commit('updateRarityList', rarityList)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async saveRarities({ dispatch, commit },{ rarities }) {
    try {
      let updatedRarities = await generatorService.saveRarities(
        rarities,
        dispatch
      );
      commit('updateRarities', rarities)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getDecorators({ dispatch, commit },{ filename }) {
    try {
      let decorators = await generatorService.getDecorators( filename );
      commit('updateDecorators', decorators)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async getDecoratorList ({ dispatch, commit }, { }) {
    try {
      let decoratorList = await generatorService.getDecoratorList( );
      commit('updateDecoratorList', decoratorList)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  },

  async saveDecorators({ dispatch, commit },{ decorators }) {
    try {
      let updatedDecorators = await generatorService.saveDecorators(
        decorators,
        dispatch
      );
      commit('updateDecorators', decorators)
    } catch (error) {
      await dispatch('alert/error', error, { root: true });
    }
  }
};
